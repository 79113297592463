import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BillRegisterTable from "./../billTable/BillRegisterTable";

import {
  postItem,
  editItem,
  dataAdd,
  setShowButton,
  setShowButtonHide,
  searchBarHandlerHide,
  fetchItem,
} from "../../store/allSlice";
import { BillRegister } from "../../billFormData";
import PaginationButton from "../UI/PaginationButton";
import BillSidebar from "../billsidebar/BillSidebar";
import Navbar from "../navbar/Navbar";

const BillRegisterForm = ({ Title, axiosRoute, editTitle }) => {
  let { data, showButton, Edit, field_error, search, successMessage } =
    useSelector((state) => state.billregister);
  document.title = "Bill Register";
  const dispatch = useDispatch();

  const [editId, setEditId] = useState(null);
  const [allData, setAllData] = useState({});
  const [editValue, setEditValue] = useState({});
  const [selectValue, setSelectValue] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [fixdate, setFixDate] = useState({});
  const [count, setCount] = useState(0);
  const [month, setMonth] = useState("");

  let bill_search_data = data?.search_customer_list;
  let billData = data?.bill_register;

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = (billData ?? data).slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  let key = null;

  const onChangeHandler = (event, navigate) => {
    let { value, checked } = event.target;

    if (Edit) {
      let current_unit = 0,
        previous_unit = 0,
        uses_unit = 0,
        due_bill = 0,
        total_bill = 0,
        sub_total = 0,
        discount = 0,
        extra_charge = 0,
        tax = 0,
        connection_type_charge = 0,
        extra_bill = 0;

      if (
        event.target.name === "current_unit" ||
        event.target.name === "previous_unit" ||
        event.target.name === "discount" ||
        event.target.name === "extra_charge" ||
        event.target.name === "due_bill"
      ) {
        current_unit =
          event.target.name === "current_unit"
            ? parseInt(event.target.value)
            : parseInt(editValue["current_unit"]);
        if (isNaN(current_unit)) {
          current_unit = 0;
        }
        previous_unit =
          event.target.name === "previous_unit"
            ? parseInt(event.target.value)
            : parseInt(editValue["previous_unit"]);
        if (isNaN(previous_unit)) {
          previous_unit = 0;
        }

        extra_charge =
          event.target.name === "extra_charge"
            ? parseInt(event.target.value)
            : 0 || parseInt(editValue["extra_charge"]);
        due_bill =
          event.target.name === "due_bill"
            ? parseInt(event.target.value)
            : 0 || parseInt(editValue["due_bill"]);
        if (isNaN(extra_charge)) {
          extra_charge = 0;
        }
        discount =
          event.target.name === "discount"
            ? parseInt(event.target.value)
            : 0 || parseInt(editValue["discount"]);

        uses_unit = parseInt(current_unit) - parseInt(previous_unit);
        connection_type_charge =
          parseInt(editValue["demand_charge"] || 0) +
          // parseInt(editValue["lowest_charge"] || 0) +
          parseInt(editValue["service_charge"] || 0) +
          parseInt(editValue["line_charge"] || 0) +
          parseInt(editValue["late_fee"] || 0);

        extra_bill =
          parseInt(editValue["disconnection_charge"]) +
          (extra_charge || 0) +
          (due_bill || 0);
        sub_total =
          parseInt(editValue["unit_start"]) >= uses_unit
            ? parseInt(editValue["lowest_charge"]) +
              extra_bill +
              parseInt(editValue["late_fee"] || 0)
            : uses_unit * parseInt(editValue.unit_price) +
              (connection_type_charge + extra_bill);

        tax = Math.round(
          (parseInt(sub_total || 0) * parseInt(editValue["vat"] || 0)) / 100
        );
        total_bill = sub_total + tax - (discount || 0);
        setEditValue({
          ...editValue,
          [event.target.name]:
            event.target.type === "checkbox" ? checked : event.target.value,
          tax: tax,
          discount: discount,
          uses_unit: uses_unit,
          total_bill: total_bill,
        });
      } else {
        setEditValue({
          ...editValue,
          [event.target.name]:
            event.target.type === "checkbox" ? checked : value,
          [key]: selectValue.value,
          tax: tax,
          uses_unit: editValue.uses_unit,
          total_bill: total_bill || editValue.total_bill,
          due_bill: due_bill,
        });
      }
    } else {
      let current_unit = 0,
        previous_unit = 0,
        uses_unit = 0,
        due_bill = 0,
        total_bill = 0,
        sub_total = 0,
        discount = 0,
        extra_charge = 0,
        tax = 0,
        connection_type_charge = 0,
        extra_bill = 0;

      if (
        event.target.name === "current_unit" ||
        event.target.name === "previous_unit" ||
        event.target.name === "discount" ||
        event.target.name === "extra_charge" ||
        event.target.name === "due_bill"
      ) {
        current_unit =
          event.target.name === "current_unit"
            ? parseInt(event.target.value)
            : parseInt(allData["current_unit"]);
        if (isNaN(current_unit)) {
          current_unit = 0;
        }
        previous_unit =
          event.target.name === "previous_unit"
            ? parseInt(event.target.value)
            : parseInt(0 || allData["previous_unit"]);
        if (isNaN(previous_unit)) {
          previous_unit = 0;
        }

        extra_charge =
          event.target.name === "extra_charge"
            ? parseInt(event.target.value)
            : 0 || parseInt(allData["extra_charge"]);

        due_bill =
          event.target.name === "due_bill"
            ? parseInt(event.target.value)
            : 0 || parseInt(allData["due_bill"]);
        if (isNaN(extra_charge)) {
          extra_charge = 0;
        }
        discount =
          event.target.name === "discount"
            ? parseInt(event.target.value)
            : 0 || parseInt(allData["discount"]);

        uses_unit = parseInt(current_unit) - parseInt(previous_unit);
        connection_type_charge =
          parseInt(allData["demand_charge"] || 0) +
          // parseInt(allData["lowest_charge"] || 0) +
          parseInt(allData["service_charge"] || 0) +
          parseInt(allData["line_charge"] || 0) +
          parseInt(allData["late_fee"] || 0);

        extra_bill =
          parseInt(allData["disconnection_charge"]) +
          (extra_charge || 0) +
          (due_bill || 0);
        sub_total =
          parseInt(allData["unit_start"]) >= uses_unit
            ? parseInt(allData["lowest_charge"]) +
              extra_bill +
              parseInt(allData["late_fee"] || 0)
            : uses_unit * parseInt(allData.unit_price) +
              (connection_type_charge + extra_bill);

        tax = Math.round(
          (parseInt(sub_total || 0) * parseInt(allData["vat"] || 0)) / 100
        );

        total_bill = sub_total + tax - (discount || 0);

        setAllData({
          ...allData,
          [event.target.name]:
            event.target.type === "checkbox" ? checked : value,
          last_paid_date: fixdate.last_paid_date,
          extra_charge: extra_charge,
          discount: discount,
          uses_unit: uses_unit,
          total_bill: total_bill,
        });
      } else {
        setAllData({
          ...allData,
          [event.target.name]:
            event.target.type === "checkbox" ? checked : value,
          [key]: selectValue.value,
          tax: tax,
          uses_unit: allData.uses_unit,
          total_bill: total_bill || allData.total_bill,
        });
        setFixDate({
          last_paid_date:
            event.target.name === "last_paid_date"
              ? event.target.value
              : fixdate,
        });
      }
    }
  };
  const onChangeSelectedValue = (e) => {
    if (Edit) {
      setEditValue({
        ...editValue,
        [key]: e.value,
      });

      let customer_search_list = billData.find(
        (item) => item.customer_id === e.value
      );

      setEditValue({ ...customer_search_list });
    } else {
      setAllData({
        ...allData,
        [key]: e.value,
      });
      let customer_search_list = bill_search_data.find(
        (item) => item.value === e.value
      );

      if (allData.value !== customer_search_list.value) {
        setAllData({
          ...customer_search_list,
          current_unit: 0,
          last_paid_date: fixdate.last_paid_date,
        });
      }
    }

    return setSelectValue(e);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (Edit) {
      dispatch(editItem(editId, editValue, axiosRoute));
      dispatch(setShowButton());
    } else {
      dispatch(postItem(allData, axiosRoute));

      setTimeout(() => {
        document.getElementById("clear").click();
      }, 300);
    }
  };

  const goBack = () => {
    dispatch(setShowButtonHide());
    dispatch(dataAdd());
    handleReset();
    dispatch(searchBarHandlerHide(true));
  };

  const EditHandler = (id) => {
    setEditId(id);

    let billDataCopy = { ...billData.find((item) => item.id === id) };
    billDataCopy.total_bill = billDataCopy?.net_bill;
    setEditValue(billDataCopy);
    dispatch(searchBarHandlerHide(false));
  };

  const handleReset = () => {
    setEditValue({});
    submitReset();
  };
  const submitReset = () => {
    setSelectValue({});
    setAllData({});
    setEditValue({});
  };
  const AddHandler = () => {
    dispatch(setShowButton());
    dispatch(searchBarHandlerHide(false));
    submitReset();
    dispatch(dataAdd());
  };
  const monthHandler = () => {
    if (month === "" || month === undefined) {
      return;
    } else {
      let filterMonth = `bill-register/?form_month=${month}`;
      dispatch(fetchItem(filterMonth));
    }
  };
  return (
    <div className="newform">
      <Navbar />
      <BillSidebar />

      <div className="newContainer">
        <div className="top">
          <h1>{showButton ? editTitle : Title}</h1>

          <button
            onClick={() => AddHandler()}
            className={showButton ? "buttonDisable" : "button"}
          >
            <AddCircleOutlineIcon className="icon" />
            Add
          </button>
        </div>
        {!showButton ? (
          <div className="showEntites" style={{ display: "flex" }}>
            <div style={{ width: "35%" }}>
              <span>show </span>
              <select onChange={(event) => setPostPerPage(event.target.value)}>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
              <span> entites</span>
            </div>

            <div className="middleDate" style={{ display: "inline-flex" }}>
              <label style={{ marginRight: "10px" }}>বিলের মাস :</label>{" "}
              <input
                type="month"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              />
              <button
                onClick={monthHandler}
                style={{ margin: "0 10px", width: "150px" }}
              >
                Search
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {showButton ? (
          <div className="bottom">
            <div className="right">
              {/* <span style={{ color: "red" }}>{message}</span> */}
              <form id="clear_data" onSubmit={onSubmitHandler}>
                <div className="form_input">
                  {BillRegister.map((input, index) => {
                    if (input.type === "select") {
                      key = input.db_name;
                      const lgSelectData = bill_search_data?.find(
                        (item) => item.value === editValue[input.db_name]
                      );
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <Select
                            className="search_dropdown"
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            name={input.db_name}
                            value={
                              Edit && Object.keys(selectValue).length === 0
                                ? lgSelectData ?? bill_search_data
                                : Object.keys(selectValue).length === 0
                                ? bill_search_data
                                : selectValue
                            }
                            defaultValue={selectValue}
                            onChange={(e) => onChangeSelectedValue(e)}
                            options={bill_search_data}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            className={
                              input.readOnly ? "input_readOnly" : "input"
                            }
                            style={{
                              border:
                                input.db_name === field_error
                                  ? "1px solid red"
                                  : null,
                            }}
                            autoComplete="off"
                            value={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name] ??
                                  fixdate[input.db_name]
                            }
                            onChange={onChangeHandler}
                            name={input.db_name}
                            checked={
                              Edit
                                ? editValue[input.db_name]
                                : allData[input.db_name]
                            }
                            type={input.type}
                            placeholder={input.placeholder}
                            required={input.required}
                            readOnly={input.readOnly}
                            hidden={input.hidden}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="form_button">
                  <button
                    className="button"
                    id="SubmitHandlerButton"
                    type="submit"
                  >
                    <SendIcon className="icon" />
                    {Edit ? "Edit" : "Send"}
                  </button>
                  <button
                    className="button"
                    type="button"
                    onClick={() => goBack()}
                  >
                    <CallMissedOutgoingIcon className="icon" />
                    Go Back
                  </button>
                  <button
                    className={Edit ? "buttonDisable" : "button"}
                    onClick={() => handleReset()}
                    type="reset"
                    id="clear"
                  >
                    <HighlightOffIcon className="icon" /> Clear
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div className="Table">
            <BillRegisterTable
              EditHandler={EditHandler}
              axiosRoute={axiosRoute}
              currentPosts={currentPosts}
              setCount={setCount}
              setPostPerPage={setPostPerPage}
              indexOfFirstPost={indexOfFirstPost}
              indexOfLastPost={indexOfLastPost}
            />

            {
              <PaginationButton
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                postPerPage={postPerPage}
                totalPosts={search.trim() === "" ? billData?.length : count}
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default BillRegisterForm;
